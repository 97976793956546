<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn color="primary" class="mx-1" :to="{ name: 'Student.Import' }">{{
        $vuetify.lang.t("$vuetify.importFromCsv")
      }}</v-btn>
      <v-btn color="primary" class="mx-1" :to="{ name: 'Student.Create' }">{{
        $vuetify.lang.t("$vuetify.newStudent")
      }}</v-btn>
    </div>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>{{ $vuetify.lang.t("$vuetify.student") }}</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="options.search = searchForm">
          <v-text-field
            v-model="searchForm"
            @click:append="clearSearch"
            :append-icon="searchForm ? 'mdi-close' : ''"
            prepend-icon="mdi-magnify"
            :label="$vuetify.lang.t('$vuetify.search')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <data-table
      item-key="userId"
      :headers="headers"
      :items="students"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
      </template>
      <template v-slot:[`item.dateOfBirth`]="{ item: { dateOfBirth } }">
        {{ dateOfBirth | removeTime }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item: { createdAt } }">
        <span>{{ createdAt | parseStringDate }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item: { id, userId } }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              {{ $vuetify.lang.t("$vuetify.option") }}
              <v-icon right dark> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showUpdateGroupDialog(userId)" link>
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.updateGroup")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="id === '-'"
              :to="{ name: 'Student.Edit', params: { id } }"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.edit")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="id === '-'" @click="deletePrompt(id)" link>
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.delete")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>

    <v-dialog
      v-model="updateGroupDialog"
      @click:outside="$refs.form.reset()"
      :persistent="updateGroupLoading"
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline"> Update Student's Group </v-card-title>
        <v-divider></v-divider>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="submit"
        >
          <v-card-text>
            <v-text-field
              v-model="form.group_code"
              :counter="5"
              :disabled="updateGroupLoading"
              :rules="rules.group_code"
              label="Group code"
              color="green"
              required
              validate-on-blur
            />
          </v-card-text>
        </v-form>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="updateGroup"
            :loading="updateGroupLoading"
            color="success"
            type="submit"
            text
          >
            Update Group
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <modal
      v-model="dialog"
      :loading="loadingDelete"
      :handler="deleteData"
      :title="$vuetify.lang.t('$vuetify.modal.deleteData.title')"
      :description="$vuetify.lang.t('$vuetify.modal.deleteData.description')"
      :text-button="$vuetify.lang.t('$vuetify.modal.deleteData.textButton')"
      :negative="true"
    />
  </v-container>
</template>

<script>
import format from "date-fns/format";
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Modal from "@/components/Modal.vue";
import StudentService from "@/services/student";

export default {
  name: "Student.Index",
  components: {
    DataTable,
    Modal
  },
  data() {
    return {
      totalData: 0,
      students: [],
      loading: true,
      loadingDelete: false,
      options: {
        search: undefined
      },
      form: {
        group_code: ""
      },
      rules: {
        group_code: [
          v => !!v || "Group code is required",
          v =>
            new RegExp(/^[A-Z0-9]{5}$/).test(v) ||
            "Group code must only contain 5 alphanumeric characters in uppercase"
        ]
      },
      headers: [
        { text: "Student ID", value: "id", width: "210px" },
        { text: "User", value: "userName", width: "210px" },
        { text: "E-mail", value: "userEmail", width: "150px" },
        { text: "Group", value: "groupName", width: "160px" },
        { text: "School", value: "schoolName", width: "160px" },
        { text: "Class", value: "class", width: "150px" },
        { text: "Exam Group", value: "examGroup", width: "120px" },
        { text: "Phone Number", value: "phoneNumber", width: "150px" },
        { text: "Major Option 1", value: "majorOption1Name", width: "250px" },
        { text: "Major Option 2", value: "majorOption2Name", width: "250px" },
        { text: "Parents' Name", value: "parentsName", width: "210px" },
        {
          text: "Parents' Phone Number",
          value: "parentsPhoneNumber",
          width: "150px"
        },
        { text: "Date of Birth", value: "dateOfBirth", width: "130px" },
        { text: "Created At", value: "createdAt", width: "145px" },
        { text: "Action", value: "action", sortable: false }
      ],
      dialog: false,
      selectedId: "",
      updateGroupDialog: false,
      updateGroupLoading: false,
      valid: false,
      searchForm: null
    };
  },
  watch: {
    options: {
      handler() {
        this.getStudents();
      },
      deep: true
    }
  },
  filters: {
    parseStringDate(date) {
      if (date === null) return "";
      return format(new Date(date), "yyyy-MM-dd HH:mm");
    },
    removeTime(datetime) {
      if (datetime === null) {
        return datetime;
      }
      return datetime.slice(0, -14);
    }
  },
  created() {
    this.setNavbarTitle("Manage Student");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getStudents() {
      this.loading = true;
      this.students = [];
      const students = await StudentService.index(this.options);
      this.students = students.data.data.students;
      this.totalData = students.data.data.total_data;
      this.loading = false;
    },
    async deletePrompt(id) {
      this.selectedId = id;
      this.dialog = true;
    },
    async deleteData() {
      try {
        this.loadingDelete = true;
        await StudentService.delete(this.selectedId);
        this.dialog = false;
        await this.getStudents();
      } catch (error) {
        this.loadingDelete = false;
      }
    },
    showUpdateGroupDialog(userId) {
      console.log(userId);
      this.updateGroupDialog = true;
      this.form.user_id = userId;
    },
    async updateGroup() {
      if (this.$refs.form.validate()) {
        this.updateGroupLoading = true;
        await StudentService.addGroup(this.form);
        this.form = {};
        this.updateGroupLoading = false;
        this.updateGroupDialog = false;
        await this.getStudents();
      }
    },
    clearSearch() {
      this.options.search = undefined;
      this.searchForm = null;
    }
  }
};
</script>
